<template>
    <aside class="nb-layout-sidebar layout-sidebar" @click="onMenuClick">
        <nav>
            <menu v-if="model" class="nb-layout-sidebar__main-menu" role="menu">
                <!-- Search -->
                <li class="nb-layout-sidebar__search">
                    <Button class="nb-text-secondary bg-white nb-border-secondary nb-rounded-md">
                        <template #icon>
                            <i class="ph ph-magnifying-glass text-xl"></i>
                        </template>
                    </Button>
                </li>

                <li v-for="menu in model[0]?.items" :key="menu.id">
                    <Button
                        @click="handleMenuClick(menu)"
                        :class="{ 'active-menu': isParentMenuActive(menu) }"
                        class="text-white" outlined text
                    >
                        <template #icon>
                            <i :class="[menu.icon, getIconSizeClass(menu.icon)]"></i>
                        </template>
                    </Button>
                    <AppMenuTooltip :label="menu.label" />
                </li>

                <!-- Switch to Cumulus-->
                <li class="nb-layout-sidebar__switcher" v-can="'switch.to.cumulus'">
                    <Button class="text-white" outlined text @click="switchToCumulus()">
                        <template #icon>
                            <i class="ph ph-arrow-fat-line-left text-2xl"></i>
                        </template>
                    </Button>
                    <AppMenuTooltip label="Switch to Cumulus" />
                </li>
            </menu>

            <div class="nb-layout-sidebar__sub-menu">
                <div class="logo-container">
                    <router-link to="/">
                        <img
                            id="app-logo"
                            :src="smallLogo"
                            class="w-full h-full nb-object-contain"
                            alt="emersion-logo"
                        />
                    </router-link>
                </div>

                <div v-if="selectedMenu">
                    <label class="font-bold text-base">{{ selectedMenu.label }}</label>
                    <menu role="menu">
                        <li v-for="subMenu in selectedMenu?.items" :key="subMenu.id">
                            <router-link :to="subMenu.to" active-class="active-menu">
                                {{ subMenu.label }}
                            </router-link>
                        </li>
                    </menu>
                </div>
            </div>
        </nav>
    </aside>
</template>

<script>
import { useEUPStore } from '@/stores/eup-store';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { getEndUserPortalHost } from '@/service/EndUserPortalService';
import HandoverToken from './service/HandOverToken';
import UserAuthenticationService from './service/UserAuthenticationService';
import AppMenuTooltip from '@/AppMenuTooltip.vue';
import { useRoute } from 'vue-router';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    props: {
        model: Array,
        layoutMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: {
        AppMenuTooltip,
    },
    setup(props) {
        const route = useRoute();
        const eupStore = useEUPStore();
        const isEUP = ref(false);
        const selectedMenu = ref(null);

        onMounted(() => {
            // by default set the eup settings in state
            useEUPStore().setEUPSettings();
            isEUP.value = !!getEndUserPortalHost();
        });

        watchEffect(() => {
            if (props.model && props.model.length > 0) {
                const activeMenu = props.model[0]?.items.find(menu => {
                    return isParentMenuActive(menu);
                });
                selectedMenu.value = activeMenu || null;
            }
        });

        const smallLogo = computed(() => {
            return eupStore.eupSettings.public_path
                ? eupStore.eupSettings.public_path.small_logo
                : 'assets/layout/images/emersion-logo-white.png';
        });

        const switchToCumulus = () => {
            let handoverToken = new HandoverToken();
            let userService = new UserAuthenticationService();

            handoverToken
                .requestHandOver(userService.getBearer())
                .then((res) => {
                   window.open(process.env.VUE_APP_CUMULUS_URL + '?handover_token=' + res.data.handover_token,'_blank')
                })
                .catch(() => {
                    this.$router.push('/error');
                });
        };

        const handleMenuClick = (menu) => {
            selectedMenu.value = menu;
        };

        const isParentMenuActive = (menu) => {
            return menu.items?.some(subMenu => route.path === subMenu.to);
        };

        // This is to control the icon sizing, since primeicons is much bigger compared to phosphor
        const getIconSizeClass = (icon) => {
            if (icon.startsWith('ph')) {
                return 'text-2xl';
            } else if (icon.startsWith('pi')) {
                return 'text-xl';
            }
            return '';
        }

        return {
            smallLogo,
            isEUP,
            switchToCumulus,
            selectedMenu,
            handleMenuClick,
            isParentMenuActive,
            getIconSizeClass,
        };
    },
};
</script>
