<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="assets/layout/images/emersion-logo-blue.png" alt="Nimbus Logo" />
            <span class="app-name">Nimbus Version {{ version }}</span>
        </div>
        <span class="copyright">Copyright &#169; 2021-2024 Emersion Systems</span>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    setup() {
        const version = process.env.VUE_APP_VERSION ?? "1.0.0";

        return {
            version
        }
    }
};
</script>